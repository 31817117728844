<template>
  <div class="expert-exp">
    <div class="d-flex flex-wrap align-center py-3 px-5">
      <div class="font-weight-bold">Experiences</div>
      <v-spacer></v-spacer>
      <v-btn to="/experience-new" color="primary" class="rounded" depressed width="106" height="30">
        <span class="text-body-2">Add new</span>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center py-5">
      <div>
        <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
        <div class="text-center text--text mt-4">Sorry, but nothing found</div>
      </div>
    </v-card>
    <div v-else>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data.result"
        item-key="id"
        :items-per-page="-1"
        class="d-sm-block d-none"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" class="link" @click="openTrip(item.id)" style="height: 64px">
              <td class="text-capitalize">{{ item.title_en }}</td>
              <td :class="item.category == 'vip' ? 'text-uppercase' : 'text-capitalize'">{{ item.category }}</td>
              <td>SAR {{ (item.price / 100).toFixed(2) }}</td>
              <td>
                {{ new Date(item.available_since).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
              </td>
              <td>
                {{ new Date(item.available_till).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
              </td>
              <td>
                <v-icon color="primary">{{ item.is_active ? 'mdi-check' : 'mdi-close' }}</v-icon>
              </td>
              <td>
                <v-icon color="primary">{{ item.featured ? 'mdi-check' : 'mdi-close' }}</v-icon>
              </td>
              <td>
                <v-icon color="primary">{{ item.is_hidden ? 'mdi-check' : 'mdi-close' }}</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <div class="mt-2 pb-2 px-3 d-flex align-center">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
        </template>
      </v-data-table>
      <div class="d-block d-sm-none">
        <div v-for="item in data.result" :key="item.id" @click="openTrip(item.id)" class="px-4 pt-2">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Experiance</div>
            <div class="font-weight-medium f18">{{ item.title_en }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Category</div>
            <div class="font-weight-medium f18 text-truncate" :class="item.category == 'vip' ? 'text-uppercase' : 'text-capitalize'">
              {{ item.category }}
            </div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Price</div>
            <div class="font-weight-medium f18">{{ (item.price / 100).toFixed(2) }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">From</div>
            <div class="font-weight-medium f18 text-capitalize">
              {{ new Date(item.available_since).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
            </div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Till</div>
            <div class="font-weight-medium f18 text-capitalize">
              {{ new Date(item.available_till).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
            </div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Active</div>
            <v-icon color="primary">{{ item.is_active ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Featured</div>
            <v-icon color="primary">{{ item.featured ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Hidden</div>
            <v-icon color="primary">{{ item.is_hidden ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
          <div class="text--text">Total {{ data.total }}</div>
          <v-spacer></v-spacer>
          <div class="ms-2">
            <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
            <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Experiance', value: 'title_en', sortable: false },
        { text: 'Category', value: 'category', sortable: false },
        { text: 'Price, SAR', value: 'category', sortable: false },
        { text: 'From', value: 'available_since', sortable: false },
        { text: 'Till', value: 'available_till', sortable: false },
        { text: 'Active', value: 'is_active', sortable: false },
        { text: 'Featured', value: 'featured', sortable: false },
        { text: 'Hidden', value: 'is_hidden', sortable: false },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openTrip(id) {
      this.$router.push(`/experiences/${id}/general`);
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getExpList', {
          search: '',
          active: 'all',
          expert: this.$route.params.expertId,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.expList;
    },
  },
  destroyed() {
    this.$store.dispatch('setExpList');
  },
};
</script>

<style lang="scss">
.expert-exp {
  th:first-of-type,
  td:first-of-type {
    padding-left: 20px !important;
  }
}
</style>
