<template>
  <div class="expert">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/experts">Experts</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.first_name }} {{ data.last_name }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div class="py-8 px-4 d-flex">
            <v-avatar color="gray lighten-2" size="52">
              <v-img v-if="data.picture != null" :src="data.picture"></v-img>
              <div v-else class="d-flex align-center">
                <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
              </div>
            </v-avatar>
            <div class="ms-3">
              <div class="text-h6 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
              <div class="text--text">@{{ data.username }}</div>
            </div>
          </div>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'edit-profile'">
          <Edit />
        </div>
        <div class="width100" v-if="link == 'experiences'">
          <Experiences />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Edit from '@/components/expert/Edit';
import Experiences from '@/components/expert/Experiences';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Edit,
    Experiences,
  },
  data() {
    return {
      link: this.$route.params.tab,
      menuItems: [
        { link: 'edit-profile', title: 'Expert profile' },
        { link: 'experiences', title: 'Experiences' },
      ],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getExpertItem');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/experts/${this.$route.params.expertId}/${l}`);
    },
  },
  computed: {
    data() {
      return this.$store.getters.expert;
    },
  },
  destroyed() {
    this.$store.dispatch('setExpertItem');
    this.$store.dispatch('setCountryList', []);
    this.$store.dispatch('setCityList');
  },
};
</script>
