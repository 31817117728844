<template>
  <div class="expert-edit">
    <v-card flat height="187" class="gray darken-5 d-flex align-center justify-center rounded">
      <div class="white--text text-center link" @click="changeImage('bg')" v-if="!data.bg_picture && !imageBg">
        <img src="@/assets/icon/upload.svg" alt="icon" />
        <div class="font-weight-bold">Add a Banner Image</div>
        <div class="text-body-2">Optional dimensions 1440x320 px</div>
      </div>
      <!-- <img v-else class="width100 bg-picture" height="187" :src="imageBg || data.bg_picture" /> -->
      <div v-else class="rounded width100">
        <img class="bg-picture width100 d-block rounded" height="187" :src="imageBg || data.bg_picture" />
        <div class="bg-picture-btn white--text text-center">
          <img src="@/assets/icon/upload.svg" alt="icon" />
          <div class="font-weight-bold">Add a Banner Image</div>
          <div class="text-body-2 mb-3">Optional dimensions 1440x320 px</div>
          <v-btn @click="changeImage('bg')" depressed color="primary lighten-1">Replace image</v-btn>
          <v-btn @click="deleteBgExpert" class="ms-3" color="white" text>Remove</v-btn>
        </div>
      </div>
    </v-card>
    <div class="d-flex flex-md-nowrap flex-wrap item-content">
      <v-card tile flat max-width="460" class="pl-lg-9 pa-sm-7 pa-3 width100">
        <div class="text-h6 font-weight-bold mb-5">Expert information</div>
        <v-text-field
          class="field46"
          v-model="data.first_name"
          placeholder="First name"
          :error-messages="firstNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.last_name"
          placeholder="Last name"
          :error-messages="lastNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-autocomplete
          class="field46 mt-n2"
          v-model="data.country"
          @change="getCity"
          placeholder="Country"
          :items="countryList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <v-autocomplete
          class="field46 mt-n2"
          v-model="data.city"
          placeholder="City"
          :error-messages="cityErrors"
          :items="cityList"
          item-text="name"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
          :disabled="!data.country"
        ></v-autocomplete>
      </v-card>
      <div class="ml-auto mr-lg-0 mr-auto px-5 mt-7">
        <div class="text-h6 font-weight-bold mb-5">Profile photo</div>
        <div class="link" @click="changeImage('ava')">
          <v-card v-if="!data.picture && !image" class="foto-card d-flex align-center" flat width="174" height="174">
            <v-img class="gray-filter" contain height="120" src="@/assets/icon/user.svg"></v-img>
          </v-card>
          <v-img cover v-else height="174" max-width="174" :src="image || data.picture"></v-img>
        </div>
        <v-btn :disabled="!file" @click="updatePhote" outlined width="176" large class="d-block text-body-2 mx-auto mt-6">
          Upload Photo
        </v-btn>
        <v-btn
          :disabled="!data.picture"
          @click="deletePhote"
          text
          width="176"
          large
          class="d-block text-body-2 mx-auto mt-1 mb-5"
          color="primary"
        >
          Remove Photo
        </v-btn>
      </div>
    </div>
    <div class="item-content pl-lg-9 pa-sm-7 pa-3 width100">
      <div class="text-h6 font-weight-bold mb-5 mt-n8">About expert</div>
      <v-textarea
        class="field46"
        v-model="data.bio"
        placeholder="Bio"
        outlined
        dense
        required
        color="primary"
        rows="5"
        auto-grow
      ></v-textarea>
      <div class="text-h6 font-weight-bold my-5">On the Web</div>
      <v-card flat class="d-flex align-center flex-wrap" max-width="540" v-for="i in socialList" :key="i.name">
        <img v-if="i.icon" class="mb-6" :src="getImage(i.icon)" :alt="i.title" />
        <div :class="i.icon ? 'ms-3' : ''" class="gray--text me-8 mb-6">{{ i.title }}</div>
        <v-spacer></v-spacer>
        <v-text-field
          style="max-width: 396px !important"
          class="field46 width100"
          v-model="data[i.name]"
          placeholder="Link"
          :error-messages="linkErrors(i.name)"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
      </v-card>
    </div>
    <v-divider class="mt-2"></v-divider>
    <div class="text-end py-3 px-sm-10 px-3">
      <v-btn @click="update" depressed color="primary" large width="136">Save</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageBg: '',
      image: '',
      file: '',
      socialList: [
        { name: 'link_facebook', title: 'Facebook', icon: 'facebook' },
        { name: 'link_instagram', title: 'Instagram', icon: 'instagram' },
        { name: 'link_twitter', title: 'Twitter', icon: 'twitter' },
        { name: 'link_tiktok', title: 'Tik-Tok', icon: 'tiktok' },
        // { name: 'link_linkedin', title: 'LinkedIn', icon: 'linkedin' },
        { name: 'link_other', title: 'Other' },
      ],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCountryList');
  },
  methods: {
    notifi(btn) {
      if (btn == 'updatePhoto') {
        this.$notify({
          title: 'Done!',
          message: 'Profile picture was successfully updated',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'updateBg') {
        this.$notify({
          title: 'Done!',
          message: 'Profile background picture was successfully updated',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({ title: 'Done!', message: 'Profile was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    async getCity() {
      await this.$store.dispatch('getCityList', this.data.country.id);
    },
    changeImage(i) {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            i == 'ava' ? (self.image = e.target.result) : ((self.imageBg = e.target.result), self.setBgExpert());
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      const formData = new FormData();
      formData.append('picture', this.file);
      await this.$store.dispatch('setPhotoExpert', formData).then(() => {
        this.file = '';
        this.image = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      await this.$store.dispatch('deletePhotoExpert').then(() => {
        this.file = '';
        this.image = '';
        this.$store.dispatch('getExpertItem');
      });
    },
    async setBgExpert() {
      const formData = new FormData();
      formData.append('picture', this.file);
      await this.$store.dispatch('setBgExpert', formData).then(() => {
        this.file = '';
        this.imageBg = '';
        this.notifi('updateBg');
      });
    },
    async deleteBgExpert() {
      await this.$store.dispatch('deleteBgExpert').then(() => {
        this.file = '';
        this.imageBg = '';
        this.$store.dispatch('getExpertItem');
      });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.first_name = this.data.first_name || '';
      data.last_name = this.data.last_name || '';
      data.username = this.data.username || '';
      data.email = this.data.email || '';
      data.bio = this.data.bio || '';
      data.link_facebook = this.data.link_facebook || '';
      data.link_instagram = this.data.link_instagram || '';
      data.link_twitter = this.data.link_twitter || '';
      data.link_tiktok = this.data.link_tiktok || '';
      data.link_other = this.data.link_other || '';
      data.city = this.data.city.id || '';
      await this.$store
        .dispatch('updateExpertItem', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    linkErrors(i) {
      const errors = [];
      this.error.find((item) => item == `${i}__invalid`) && errors.push('Provided link is not valid');
      return errors;
    },
  },
  watch: {
    data(val) {
      if (val.country) {
        this.getCity();
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.expert;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter last name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    userNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'username__required') && errors.push('Please enter username');
      this.error.find((item) => item == 'username__invalid') && errors.push('Provided username is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
    cityErrors() {
      const errors = [];
      this.error.find((item) => item == 'city__required') && errors.push('Please enter city');
      this.error.find((item) => item == 'city__invalid') && errors.push('Provided city is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.expert-edit {
  .bg-picture {
    object-fit: cover;
  }
  .bg-picture-btn {
    display: none;
    position: absolute;
    padding-top: 20px;
    top: 0;
    left: 0;
    right: 0;
    height: 187px;
    background: rgba($color: #3d3d3d, $alpha: 0.6);
    z-index: 9;
  }
  .rounded:hover {
    .bg-picture-btn {
      display: block;
    }
  }
}
</style>
